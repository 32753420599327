import { GetStaticProps, GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import React from 'react';
import { Locales } from '../types/enums';
import { useTranslationContext } from '../provider/TranslationProvider';
// import Lottie from 'lottie-react';
import animationData from '../public/json/404-sink.json';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

const PageNotFound = () => {
  const { LabelConstants } = useTranslationContext();
  return (
    <div className="flex flex-col items-center justify-center bg-[#F5F5F5] py-[70px] lg:pt-[92px]">
      <div className="relative">
        <Lottie
          animationData={animationData}
          className="flex justify-center items-center !scale-[1] lg:!scale-[2]"
          loop={true}
        />
      </div>
      <div className="relative top-[0px] lg:top-[80px] w-[75%] lg:w-[35%] font-normal text-[18px] lg:text-[30px] leading-[22.68px] lg:leading-[42.84px] text-center">
        {LabelConstants.PAGE_NOT_FOUND}
      </div>
      <div className="relative top-[0px] lg:top-[80px] pt-[48px] lg:pb-[92px]">
        <Link
          href={{
            pathname: globalThis?.sessionStorage
              ? globalThis?.sessionStorage?.getItem('prevPath')
              : '/',
          }}
        >
          <button className="min-w-[214px] h-[48px] text-[#FFFFFF] bg-[#212121] rounded-[88px] text-[15px] lg:text-[18px] leading-[18.9px] lg:leading-[22.68px]">
            <a>{LabelConstants.TRY_AGAIN}</a>
          </button>
        </Link>
      </div>
    </div>
  );
};
export default PageNotFound;

export const getStaticProps: GetStaticProps = async ({
  locale,
}: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || Locales.EN, ['common'])),
    },
  };
};
